import React, { Component, lazy, Suspense, useEffect } from 'react';
import axios from 'axios';
import ReactPixel from 'react-facebook-pixel';
import ReactPlayer from 'react-player';
import { v4 as uuidv4 } from 'uuid';
import './App.css';

//import Navbar from './componentes/Navbar';
import Carousel, { CarouselItem } from './componentes/Carousel/Carousel';
//import CarouselBlog, { CarouselItemBlog } from './componentes/BlogCarousel/BlogCarousel';
//import Search from './componentes/Search/Search';
//import textBanner from './assets/images/text-banner.webp';
//import productosBanner from './assets/images/panes-brios-banner.webp';
//import iconPedido from './assets/images/icon-pedido.webp';
import frutosRojos from './assets/images/frutos-rojos.webp';
import nuez from './assets/images/nuez.webp';
import b_flecha from './assets/images/b-flecha.webp';

import cereales from './assets/images/brios-cereales-b.webp';
import semillas from './assets/images/brios-semillas-b.webp';
import frutal from './assets/images/brios-frutas-b.webp';

import cerealesLarge from './assets/images/Brios-Cereales-Descripcion-Desktop.webp';
import semillasLarge from './assets/images/Brios-Semillas-Descripcion-Desktop.webp';
import frutalLarge from './assets/images/Brios-Frutal-Descripcion-Desktop.webp';

import semillasSmall from './assets/images/Brios-Semillas-Descripcion-Mobile.webp';
import frutalSmall from './assets/images/Brios-Frutal-Descripcion-Mobile.webp';
import CerealesSmall from './assets/images/Brios-Cereales-Descripcion-Mobile.webp';

import coco from './assets/images/coco.webp';
//import geolocalizacion from './assets/images/icon-geolocalizacion.webp';
//import imgBlog from './assets/images/img-blog.webp';
import logoWhite from './assets/images/logo-brios.webp';
import address from './assets/images/icon-address.webp';
import instagram from './assets/images/icon-instagram.webp';
import facebook from './assets/images/icon-facebook.webp';
import email from './assets/images/icon-email.webp';
import loading from './assets/images/loading.gif';
import iconTiendaVirtual from './assets/images/icono-tienda-virtual.webp';


//import { CarouselItem } from './componentes/Carousel/Carousel';
const Navbar = lazy(() => import('./componentes/Navbar'));
//const Carousel = lazy(() => import('./componentes/Carousel/Carousel'));
const Search = lazy(() => import('./componentes/Search/Search'));
const ProductModal = lazy(() => import('./componentes/Modal/productModal'));


const renderLoader = () => <p>Loading</p>;

//cereales $6.200  -  semillas $6.800  -  frutal $8600  -  coconut $8000
const products = [
  {
    id: 1,
    name: 'Brios Semillas',
    imageUrl: semillas,
    imageLarge: semillasLarge,
    imageSmall: semillasSmall,
    description: 'Pan Brios con semillas. Rico en fibras y nutrientes esenciales.',
    precio: 6800
  },
  {
    id: 2,
    name: 'Brios Frutal',
    imageUrl: frutal,
    imageLarge: frutalLarge,
    imageSmall: frutalSmall,
    description: 'Pan Brios con frutas. Una explosión de sabor y vitaminas.',
    precio: 8600
  },
  {
    id: 3,
    name: 'Brios Cereales',
    imageUrl: cereales,
    imageSmall: CerealesSmall,
    imageLarge: cerealesLarge,
    description: 'Pan Brios con cereales. Perfecto para un desayuno saludable.',
    precio: 6200
  }
];

  // Función para obtener fbc y fbp
  const getFbcFbp = () => {
    const cookies = document.cookie.split(';');
    let fbc = '';
    let fbp = '';
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith('_fbp')) {
        fbp = cookie.split('=')[1];
      } else if (cookie.startsWith('_fbc')) {
        fbc = cookie.split('=')[1];
      }
    }
    return { fbc, fbp };
  };

  // Obtener fbc y fbp al inicio de la sesión
  const { fbc, fbp } = getFbcFbp();
  console.log("fbc:",fbc, "fbp:", fbp);


  class App extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      scroll: false,
      error: false,
      mensajeError: "",
      styleError: "",
      /* ----------- Pixel Original ----------- */
      //access_token: "EAAFDiZCpYcz0BALr9oCKrZBCWZCdvEe2I2yZBWEQZCVxtyPb2euXFHJbB1bJ6UTLCfxWMbmfSWFQpEAJrBvnAJ8nHO4QfuysStyoi4lw6OWngkMJq6EDkxJh05uCV43NHtav8Xyrke3dUMsn3FLH2ErQgVDwD95azD5kXDLPXFBzclksPdotcaJKiGH6R5LQZD",
      //pixel_id: "170743725217065",
      /* ----------- Pixel Original ----------- */

      /* ----------- Pixel de Pruebas ----------- */
      access_token: "EAAFDiZCpYcz0BO9WHZB47t637s7Mzo31HrPyqpOo2AxCmEAoXAFXTk0q6p1JZBs31USUjFAT5PSVuKPSrtCAGZANCF9ZCC7AeXqZCKqcMDCEL7cxQ80dutPZBLmRxZCUO27n0czwFs86LIA6mHkFD0UB2vOEdvYP13ciZAUF2WBCjfwZCdMxPTJw4LYuAvb9bH1nNlJAZDZD",
      pixel_id: "2565479776955371",
      /* ----------- Pixel de Pruebas ----------- */

      ip: "",
      selectedProduct: null,
      windowWidth: window.innerWidth
    }
    this.sectionRef = React.createRef();
  }

   

  scrollToSection = () => {
    if (this.sectionRef.current) {
      this.sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  componentDidMount() {
    //const pixel_id = '170743725217065';
    const pixel_id = this.state.pixel_id;
    window.addEventListener('resize', this.handleResize);

    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    };

    // Verificar si el píxel ya está inicializado
    if (!window.fbPixelInitialized) {
      ReactPixel.init(pixel_id, options);
      window.fbPixelInitialized = true; // Marcar el píxel como inicializado
    }

    this.getData();
    this.preloadImages();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  //creating function to load ip address from the API
  getData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    //console.log(res.data);
    //console.log(navigator)
    const ipUser = res.data.IPv4;
    this.setState({ ip: ipUser });
    this.serverPageView(ipUser);
  }


  initialError = (data) => {
    this.setState({ error: data, mensajeError: "", styleError: "" })
  }

  // Método para generar un ID único
  //generateId = () => Math.random().toString(36).substr(2, 18);
  generateId = () => uuidv4();

  // Método para enviar el evento de PageView al cliente y servidor
  serverPageView = (ipUser) => {

    const id_event = this.generateId();
    let current_timestamp = Math.floor(new Date() / 1000);
    console.log("pageView: ",id_event, fbc,fbp);

    // Enviar evento de PageView al cliente (navegador)
    ReactPixel.pageView({ 
      eventID: id_event,
      user_data: {
        fbc: fbc,
        fbp: fbp
      }
    });

    // Datos del evento para enviar al servidor
    const data = {
      "data": [
        {
          "event_name": "PageView",
          "event_time": current_timestamp,
          "event_id": id_event,
          "action_source": "website",
          "user_data": {
            "client_ip_address": ipUser,
            "client_user_agent": window.navigator.userAgent,
            "fbc": fbc,
            "fbp": fbp
          }
        }
      ]
    };

    // Enviar el evento al servidor de Facebook
    fetch(
      `https://graph.facebook.com/v12.0/${this.state.pixel_id}/events?access_token=${this.state.access_token}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      }
    )
    .then(response => response.json())
    .then(response => {
      // Manejo de la respuesta del servidor de Facebook
      console.log(data);
      console.log("Evento enviado con éxito:", response);
    })
    .catch(error => {
      // Manejo de errores
      console.error("Error al enviar el evento:", error);
    });
  }


  handleEnded = (props) => {
    // Generar un ID único para el evento
    const id_event = this.generateId();

    // Enviar el evento al Pixel de Facebook en el cliente
    ReactPixel.trackCustom('ViewYoutube', 
      { eventID: id_event, 
        content_type: 'Terminó video de youtube completo',
        fbc: fbc,
        fbp: fbp
      },
      { eventID: id_event }
    );

    //console.log(`Evento ${'ViewYoutube'} enviado con eventID: ${id_event} y fbc: ${fbc}`);

    // Obtener la marca de tiempo actual
    let current_timestamp = Math.floor(new Date() / 1000);

    // Datos del evento para enviar al servidor de Facebook
    const data = {
      "data": [
        {
          "event_name": "ViewYoutube",
          "event_time": current_timestamp,
          "event_id": id_event,
          "action_source": "website",
          "user_data": {
            "client_ip_address": this.state.ip,
            "client_user_agent": window.navigator.userAgent,
            "fbc": fbc,
            "fbp": fbp
          },
          "custom_data": {
            "content_type": "Terminó video de youtube completo"
          }
        }
      ]
    };


    // Enviar el evento al servidor de Facebook
    fetch(
      `https://graph.facebook.com/v12.0/${this.state.pixel_id}/events?access_token=${this.state.access_token}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      }
    )
    .then(response => response.json())
    .then(response => {
      // Redirigir a la sección de distribuidores después de enviar el evento
      console.log(data);
      console.log('Evento del servidor enviado con éxito:', response);
      this.scrollToSection();
      //window.location.href = "#distribuidores";
    })
    .catch(error => {
      // Manejar errores
      console.error("Error al enviar el evento:", error);
    });
  }

  preloadImages = () => {
    products.forEach(product => {
      const img = new Image();

      this.state.windowWidth < 800 ? 
        img.src = product.imageSmall
        : 
        img.src = product.imageLarge

    });
  };


  handleImageClick = (product) => {
    this.setState({ selectedProduct: product });
  }

  handleCloseModal = () => {
    this.setState({ selectedProduct: null });
  }


  render() {

    return this.state.ip === "" ? <div className="loading d-flex justify-content-center align-items-center"><img src={loading} /></div> : <>
      <div className="main">
        <Suspense fallback={renderLoader()}>
          <Navbar />
        </Suspense>
      </div>
      <div id="home" className="box">
        <div className="text">
          <div className="player-wrapper">
            <ReactPlayer
              url={'https://www.youtube.com/watch?v=lht7fludNC4'}
              width='100%'
              height='100%'
              controls={true}
              playing={true}
              onEnded={this.handleEnded}
              config={{
                file: {
                  attributes: {
                    autoPlay: true,
                    muted: true
                  }
                },
                youtube: {
                  preload: true,
                }
              }}
            />
          </div>
          {/*<div className="container">
            <div className="d-flex flex-column flex-sm-column flex-md-column flex-lg-row justify-content-lg-around align-items-center">
              <div><h1 className="text-center"><img className="title img-fluid" src={textBanner} alt="Brios tu alternativa rica y saludable - ¡Pruebaló ya!" /></h1></div>
              <div><img className="img-fluid" src={productosBanner} alt="Panes Brios - FRUTAS, CEREALES Y SEMILLAS" /></div>
            </div>
          </div>
          <div className="tablaFondo d-flex align-items-end justify-content-center">
            <button type="button" onClick={(e) => { window.location = '#distribuidores' }} className="btn btn-primary appbtn fs-3 px-4 py-3 mb-5">
              ¡Ordenar Ahora! <img src={iconPedido} width="50px" alt="Distribuidores" />
            </button>
          </div>*/}
        </div>
      </div>
      <div ref={this.sectionRef} id="distribuidores" className="b-mt b-pan w-100 mb-5">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-2 text-start"></div>
            <div className="col-sm-12 col-md-12 col-lg-8 text-center">
              {/*<h2 className="text-center"><span>¿Dondé puedo pedir</span> <br /> Pan Brios?</h2>
              <p className="text-justify pt-5">
                Brios lo puedes encontrar en diferentes <strong>minimarkets</strong> de la ciudad de <strong>Cali.</strong>
                <br />
                <br />
                <span className="text-nowrap bd-highlight">Has tu pedido</span> a los minimarkets más cercanos de tu ubicación actual:
                <br />
                <strong>Opcion 1:</strong> Da clic en el <strong>icono</strong> <img src={geolocalizacion} /> de geolocalización.
                <br />
                <strong>Opcion 2:</strong> Ingresa tu <strong>barrio</strong> en la barra de busqueda.
              </p>*/}
              <h2 className="text-center">Pan Brios</h2>
              <p className="p-title text-center">
                <span>Encuentra el <span className="text-white bg-brown rounded-1 px-2 pb-1">punto de venta</span> más cercano.</span>
              </p><br/><br/>
              <p className="p-title2 text-center mt-2">
                <strong>BUSCAR POR:</strong><br />
                {/*<i className="fas fa-arrow-down"></i>*/}
              </p>
              <div id="form" className="row mt-2">
                <div className="col-sm col-md col-lg">
                  <Suspense fallback={renderLoader()}>
                    {this.state.ip != "" ? <Search listResult={this.setListResult} listItems={this.state.listResult} ip={this.state.ip ? this.state.ip : ""} access_token={this.state.access_token} pixel_id={this.state.pixel_id} fbc={fbc} fbp={fbp} /> : "Loading"}
                  </Suspense>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-2 text-end"></div>
            <div className="col-sm-12 col-md-12 col-lg-2 text-start"></div>
            <div className="col-sm-12 col-md-12 col-lg-8 text-center">
            {/*<p className="p-title text-center">
                <span>ó <span className="text-white bg-brown rounded-1 px-2 pb-1">compra en línea</span> ingresando a</span>
              </p>
              <p className="p-title2 text-center mt-2">
                <strong>NUESTRA TIENDA VIRTUAL</strong><br />
                <i className="fas fa-arrow-down"></i>
              </p>
              <div>
                <a href="https://panbrios.miexpone.co/" target="_blank" className="btn btn-primary appbtn px-4 py-2 w-100 mb-3">
                  <img src={iconTiendaVirtual} alt="icono tienda virtual" height="60px" /> <span>Ir a Tienda Virtual</span>
                </a>
            </div>*/}
            </div>
            <div className="col-sm-12 col-md-12 col-lg-2 text-end"><img src={coco} className="w-image" /></div>
          </div>
        </div>
      </div>
      <div id="productos" className="parallax w-100 pt-5">
        <img className="nuez w-image" src={nuez} />
        <div className="container pt-5">
          <div className="row">
            <h4 className="text-center pt-4 pb-3">CONOCE NUESTROS PRODUCTOS</h4>
            <p className="text-center mb-5">Naturalmente ricos en fibra, vitaminas y minerales <br />que te nutren y ayudan a mejorar la digestión.</p>
          </div>
        </div>
        <img className="flecha w-100 mt-4" src={b_flecha} />
      </div>
      <div className="product bg-white">
        <div className="container">
          <div className="row">
            <Suspense fallback={renderLoader()}>
            <Carousel>
              {products.map((product) => (
                  <CarouselItem key={product.id}>
                    <div className="d-flex flex-column text-center align-items-center">
                      <img
                        src={product.imageUrl}
                        alt={product.name}
                        onClick={() => this.handleImageClick(product)}
                        className="product-image img-fluid mb-3"
                        width="400px" height="500px"
                      />
                      <h4>{product.name}</h4>
                      <button type="button" onClick={() => this.handleImageClick(product)} className="btn btn-primary appbtn px-4 py-2 mb-3">
                            Ver Detalles
                        </button>
                    </div>
                  </CarouselItem>
                ))}
              </Carousel>
            </Suspense>
          </div>
        </div>
      </div>
      <div className="container border-top">
        <div id="grupo-brios" className="row pt-5">
          <div className="col-sm-12 col-md-12 col-lg pt-5 pb-lg-5 d-flex align-items-center justify-content-center">
            <h2 className="text-center"><span>Línea</span> <br /> Brios </h2>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-8 d-flex align-items-center pt-lg-5 px-5">
            <div>
              <p className="text-justify pt-5">Sabemos que deseas alimentar a tu familia y a ti de forma saludable sin renunciar al placer de comer rico.
                <br />
                <br />
                La línea de pan tajado Brios te brinda el balance perfecto entre rico y saludable al combinar frutas, miel, nueces, cereales y semillas con nuestra masa madre 100% integral alta en fibra, todos sus ingredientes son de origen natural y de primera calidad.
                <br />
                <br />
                <strong>Somos tu alternativa rica y saludable</strong> en tu camino hacia un <strong>estilo de vida mejor.</strong>
              </p>
            </div>
          </div>
          <p className="text-end"><img src={frutosRojos} className="w-image" /></p>
        </div>
      </div>
      {/*<div id="blog" className="container py-5">
        <div className="row">
          <CarouselBlog>
            <CarouselItemBlog>
              <div className="container">
                <div className="row">
                  <div className="contBlog col-sm col-md-12 col-lg-6 text-start">
                    <h3><span>Descubre:</span> <br /> ¿Como saber si un pan es 100% integral?</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                  </div>
                  <div className="col-sm col-md-12 col-lg-6">
                    <img className="img-fluid" src={imgBlog} alt="Panes Brios Blog" />
                  </div>
                </div>
              </div>
            </CarouselItemBlog>
          </CarouselBlog>
        </div>
      </div>*/}
      <footer id="contacto" className="b-footer w-100 mt-5">
        <div className="col-sm-12 col-md-12 col-lg-6 container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg border-right pt-4">
              <p className="text-center"><img className="img-fluid" src={logoWhite} alt="Logo Pan Brios" /></p>
            </div>
            <div className="col-sm-12 col-md-12 col-lg pt-4 mb-3">
              <p className='mb-3'><i className="fa-solid fa-globe"></i>www.brios.com.co</p>
              <p className='mb-3'>
                <a href='mailto:contacto@brios.com.co' target='_blank' alt="Enviar correo al email de Brios">
                  <i className="fa-solid fa-envelope"></i>contacto@brios.com.co
                </a>
              </p>
              <p className='mb-3'>
                <a href='#distribuidores' alt="Ubicación de Brios">
                  <i className="fa-solid fa-location-dot"></i>Cali - Colombia
                </a>
              </p>
              <p className="mb-3 mt-3">Conoce más en:</p>
              <p className='redes_sociales mb-3 mt-3'>
                <a href="https://www.instagram.com/brios_panaderia/" className='instagram' target="_blank" alt="ruta a instagram de Brios">
                  <i className="fa-brands fa-instagram"></i>
                </a>
                <a href="https://www.facebook.com/BriosPanaderia" className='facebook' target="_blank" alt="ruta a facebook de Brios">
                  <i className="fa-brands fa-facebook-f"></i>
                </a>
              </p>
            </div>
            <p className="text-center fs-6 mt-2 text-gray-600">
              © Brios 2024. Todos los derechos reservados.
            </p>
          </div>
        </div>
      </footer>

      {this.state.selectedProduct && (
        <Suspense fallback={renderLoader()}>
          <ProductModal
            product={this.state.selectedProduct}
            onClose={this.handleCloseModal}
            access_token={this.state.access_token}
            pixel_id={this.state.pixel_id}
            fbc={fbc}
            fbp={fbp}
            ip={this.state.ip}
          />
        </Suspense>
      )}
    </>;
  }
}

export default App;
